import React from "react";

const Progects: React.FC = () => {
  return (
    <div>
      <h1>Projects</h1>
    </div>
  );
};

export default Progects;
