import React from "react";

const Home: React.FC = () => {
  return (
    <div>
      <h1>ברוכים הבאים לאתר שלי!</h1>
    </div>
  );
};

export default Home;
